import axios from '../axios.instance'

export default class TourGuideService {
    async getTourGuides(){
        try {
            const res = await axios.get('/tourguide');
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getTourGuideById(id){
        try {
            const res = await axios.get('/tourguide/'+id);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async searchTourGuide(data) {
        try {
            if(data != ''){
                const res = await axios.get('/tourguide/search/', {params: {guidename: data}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async getLanguage(id) {
        try {
            if(id != 0){
                const res = await axios.get('/tourguide/getlanguage/', {params: {guideid: id}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addTourGuide(data, language_data){
        try {
            if(data != ''){
                const res = await axios.post('/tourguide', {data, language_data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateTourGuide(tourguide_id, data, language_data) {
        try {
            if(data != '') {
                const res = await axios.put('/tourguide/'+tourguide_id, {data, language_data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteTourGuide(tourguide_id, photoName) {
        try {
            if (tourguide_id != 0) {
                const res = await axios.delete('/tourguide/'+tourguide_id, { params: { photoname: photoName } });
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //Tour Guide Language
    async getLanguages() {
        try {
            const res = await axios.get('/tourguide/languages');
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async filterTourGuide(areaFilter, languageFilter) {
        try {
            const res = await axios.post('/tourguide/filter-tourguide', {areaFilter, languageFilter});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
}