<template>
<div class="booking-list">
    <div class="p-grid p-fluid">
        <div class="p-col">
            <Dropdown v-model="selectedFilterBy" :options="groupedFilterBy" optionGroupLabel="label" optionGroupChildren="items" optionLabel="by" style="height: 33px">
                <template #optiongroup="slotProps">
                    <div class="p-d-flex p-ai-center">
                        <span v-if="slotProps.option.label == 'Search by:'" class="pi pi-search p-mr-2" style="fontSize: .9rem"></span>
                        <span v-if="slotProps.option.label == 'Filter by:'" class="pi pi-filter p-mr-2" style="fontSize: .9rem"></span>
                        <div>{{slotProps.option.label}}</div>
                    </div>
                </template>
            </Dropdown>
        </div>
        <div class="p-col">
            <Dropdown :editable="selectedFilterDate && selectedFilterDate.date != 'This month' && selectedFilterDate.date != 'Last month' && selectedFilterDate.date != 'Next month'" v-model="selectedFilterDate" @change="onFilterDateChange()" style="height: 33px; " :options="filterDate" optionLabel="date" :disabled="selectedFilterBy && selectedFilterBy.by == 'Tour code'" />
            <Calendar ref="customDate" v-model="filterCustomDate" @date-select="onCustomDateSelect()" id="arrivalfrom_date" dateFormat="dd-M-yy" style="height: 33px; visibility: hidden; position: absolute; top: 80px; right: 120px" />
        </div>
    </div>
    <div class="p-grid p-fluid">
        <div class="p-col">
            <div v-if="selectedFilterBy && selectedFilterBy.by == 'Tour code'" class="p-input-icon-left search-field p-mr-2">
                <i class="pi pi-search" />
                <InputText
                    type="text"
                    :placeholder="searchPlaceholder"
                    @keyup="searchByTourCode($event)"
                    :disabled="selectedFilterBy && selectedFilterBy.by == 'Booking'"
                />
            </div>
            <div v-if="selectedFilterBy && selectedFilterBy.by != 'Tour code'">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <AutoComplete v-model="selectedSupplier" :suggestions="filteredSupplier" @complete="searchSupplier($event)" @item-select="filterBooking()" :field="supplier_field" inputClass="custom-autocomplete-icon" inputStyle="height: 33px;" :disabled="selectedFilterBy && selectedFilterBy.by == 'Booking'" :placeholder="searchPlaceholder" />
                </span>
            </div>
        </div>
        <div v-if="can('create', 'booking')" class="p-col-fixed">
             <router-link to="/booking/new-booking" target="_blank"> 
                <Button label="New Booking" class="p-button-sm" style="width: 110px" />
            </router-link>
        </div>
    </div>

    <div class="content-section implementation">
        <div class="booking-datatable card">
            <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" />
            <DataTable
                :value="bookings"
                responsiveLayout="scroll"
                scrollHeight="300px"
                v-model:selection="selectedBooking" 
                selectionMode="single"
                @rowSelect="onRowSelect"
                dataKey="id"
                v-if="!loadingError.isError"
            >
                <Column field="id" header="ID" :style="{'min-width':'70px'}"></Column>
                <Column field="tour_code" header="Tour code" :style="{'min-width':'190px'}"></Column>
                <Column field="pax" header="Pax" :style="{'min-width':'50px'}"></Column>
                <Column field="nationality" header="Nationality" :style="{'min-width':'100px'}"></Column>
                <Column field="booking_company" header="Company" :style="{'min-width':'100px'}"></Column>
                <Column field="booking_status" header="Status" :style="{'min-width':'100px'}">
                    <template #body="slotProps">
                        <Tag class="custom-tag p-mr-2" v-if="slotProps.data.booking_status == 'confirmed'" severity="success"  style="width: 70px" :value="slotProps.data.booking_status.toUpperCase()"></Tag>
                        <Tag class="custom-tag p-mr-2" v-if="slotProps.data.booking_status == 'cancelled'" severity="danger"  style="width: 70px" :value="slotProps.data.booking_status.toUpperCase()"></Tag>
                        <Tag class="custom-tag p-mr-2" v-if="slotProps.data.booking_status == 'tba'" severity="warning" style="width: 70px" :value="slotProps.data.booking_status.toUpperCase()"></Tag>
                    </template>
                </Column>

                <Column v-if="can('update', 'booking') || can('delete', 'booking')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <router-link :to="'/booking/update-booking/'+slotProps.data.id" target="_blank"> 
                            <Button v-if="can('update', 'booking')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" />
                        </router-link>
                        <Button v-if="can('delete', 'booking')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteBooking(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
            <ConfirmDialog></ConfirmDialog>
        </div>

        <div class="tabmenu card">
            <TabView v-model:activeIndex="activeTab" class="tabview-custom-header" @tab-click="onTabChanged()">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-map-marker"></i>
                        <span style="min-width: 5.3rem">Arrival Info</span>
                    </template>
                    <BookingArrivalInfo ref="BookingArrivalInfoRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-map"></i>
                        <span>Itinerary</span>
                    </template>
                    <BookingItineraryInfo ref="BookingItineraryInfoRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-building"></i>
                        <span>Hotel</span>
                    </template>
                    <BookingHotel ref="BookingHotelRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-palette"></i>
                        <span>Restaurant</span>
                    </template>
                    <BookingRestaurant ref="BookingRestaurantRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-user"></i>
                        <span style="min-width: 5.4rem">Tour Guide</span>
                    </template>
                    <BookingTourGuide ref="BookingTourGuideRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-car"></i>
                        <span>Transportation</span>
                    </template>
                    <BookingTransport ref="BookingTransportRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-wallet"></i>
                        <span style="min-width: 6.5rem">Optional Tour</span>
                    </template>
                    <BookingOptionalTour ref="BookingOptionalTourRef" />
                </TabPanel>
            </TabView>
        </div>
    </div>
</div>  
</template>

<script>
import  { ref, reactive, onMounted, watchEffect, defineAsyncComponent } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import dayjs from 'dayjs';
import BookingService from '../service/BookingService';
import HotelService from '../service/HotelService';
import RestaurantService from '../service/RestaurantService';
import TourGuideService from '../service/TourGuideService';
import TransportationService from '../service/TransportationService';

const LoadingDataError = defineAsyncComponent(() => import(/* webpackChunkName: "loadingdataerror" */'../components/LoadingDataError.vue')) 
const BookingArrivalInfo = defineAsyncComponent(() => import(/* webpackChunkName: "bookingarrivalinfo" */'../components/Booking_ArrivalInfo.vue'));
const BookingItineraryInfo = defineAsyncComponent(() => import(/* webpackChunkName: "bookingitineraryinfo" */'../components/Booking_Itinerary.vue'));
const BookingHotel = defineAsyncComponent(() => import('../components/Booking_Hotel.vue'));
const BookingRestaurant = defineAsyncComponent(() => import (/* webpackChunkName: "bookingrestaurant" */'../components/Booking_Restaurant.vue'))
const BookingTourGuide = defineAsyncComponent(() => import (/* webpackChunkName: "bookingtourguide" */'../components/Booking_TourGuide.vue'))
const BookingTransport = defineAsyncComponent(() => import (/* webpackChunkName: "bookingtransport" */'../components/Booking_Transport.vue'))
const BookingOptionalTour = defineAsyncComponent(() => import (/* webpackChunkName: "bookingoptionaltour" */'../components//Booking_OptionalTour.vue'))

export default {
    setup() {
        const store = useStore()
        const toast = useToast()
        const confirm = useConfirm()
        const { can } = useAbility()

        // let loading = ref(false)
        let loadingError = reactive({isError: false, errorCode: ''})
        let bookings = ref([])
        let selectedBooking = ref()
        let activeTab = ref(0)

        const BookingArrivalInfoRef = ref()
        const BookingItineraryInfoRef = ref()
        const BookingHotelRef = ref()
        const BookingRestaurantRef = ref()
        const BookingTourGuideRef = ref()
        const BookingTransportRef = ref()
        const BookingOptionalTourRef = ref()

        const searchPlaceholder = ref('')
        const groupedFilterBy = ref([
            {
                label: 'Search by:',
                items: [
                    {by: 'Tour code'}
                ]
            },
            {
                label: 'Filter by:',
                items: [
                    {by: 'Booking'},
                    {by: 'Hotel'},
                    {by: 'Restaurant'},
                    {by: 'Tour guide'},
                    {by: 'Transport Company'},
                ]
            },
        ]);
        let selectedFilterBy = ref()
        const filterDate = ref([
            {date: 'Last month'},
            {date: 'This month'},
            {date: 'Next month'},
            {date: 'Custom date'},
        ])
        let selectedFilterDate = ref()
        let filterCustomDate = ref()
        const customDate = ref()
        let timeout;
        
        const bookingService = ref(new BookingService())
        const hotelService = ref(new HotelService())
        const restaurantService = ref(new RestaurantService())
        const tourguideService = ref(new TourGuideService())
        const transService = ref(new TransportationService())

        onMounted(() => {
            // loading.value = true;
            selectedFilterBy.value = {by: 'Booking'}
            getBookingInfo()

            store.dispatch('showLoadingScreen', false)
        })

        //Method
        const onRowSelect = () => {
            getBookingDetail()
        }

        const getBookingInfo = () => {
            // const waitAPI = setTimeout(() => {
            //     showProgressBar.show = true
            // }, 400)
            bookingService.value.getBookingData().then(data => {
                if(!data.errorResponse) {
                    bookings.value = data
                    // clearInterval(waitAPI)
                } else {
                    // clearInterval(waitAPI)
                    loadingError.isError = true;
                    loadingError.errorCode = data.status
                }
            })
        }

        const onTabChanged = () => {
            if(selectedBooking.value) {
                getBookingDetail()
            }
        }

        const getBookingDetail = () => {
            if(activeTab.value == 0) {
                BookingArrivalInfoRef.value.getBookingArrivalInfo(selectedBooking.value.id)
            }
            if(activeTab.value == 1) {
                BookingItineraryInfoRef.value.getBookingItinerary(selectedBooking.value.id)
            }
            if(activeTab.value == 2) {
                BookingHotelRef.value.getHotelBooking(selectedBooking.value.id)
            }
            if(activeTab.value == 3) {
                BookingRestaurantRef.value.getRestaurantBooking(selectedBooking.value.id)
            }
            if(activeTab.value == 4) {
                BookingTourGuideRef.value.getTourGuideBooking(selectedBooking.value.id)
            }
            if(activeTab.value == 5) {
                BookingTransportRef.value.getTransportBooking(selectedBooking.value.id)
            }
            if(activeTab.value == 6) {
                BookingOptionalTourRef.value.getOptionalTourBooking(selectedBooking.value.id, selectedBooking.value.itinerary_id)
            }
        }

        const confirmDeleteBooking = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete booking "'+ data.tour_code +'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const booking_id = data.id
                    bookingService.value.deleteBooking(booking_id).then((data) => {
                        if(!data.errorResponse){
                            bookings.value = bookings.value.filter(val => val.id !== booking_id);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        watchEffect(() => {
            if(selectedFilterBy.value) {
                selectedSupplier.value = null;

                if(selectedFilterBy.value.by == 'Tour code') {
                    searchPlaceholder.value = 'Find by tour code'
                }
    
                if(selectedFilterBy.value.by == 'Booking') {
                    searchPlaceholder.value = ''
                    selectedSupplier.value = null
                }
    
                if(selectedFilterBy.value.by == 'Hotel') {
                    searchPlaceholder.value = 'Hotel name'
                }
    
                if(selectedFilterBy.value.by == 'Restaurant') {
                    searchPlaceholder.value = 'Restaurant name'
                }
    
                if(selectedFilterBy.value.by == 'Tour guide') {
                    searchPlaceholder.value = 'Tour guide name'
                }
    
                if(selectedFilterBy.value.by == 'Transport Company') {
                    searchPlaceholder.value = 'Transport company name'
                }
            }
        })

        const onFilterDateChange = () => {
            if(selectedFilterDate.value.date == 'Custom date') {
                customDate.value.overlayVisible = true
            } else {
                filterBooking()
            }

            // if(selectedFilterDate.value.date == 'This month') {
            //     filterBooking()
            // }

            // if(selectedFilterDate.value.date == 'Last month') {
            //     filterBooking()
            // }

            // if(selectedFilterDate.value.date == 'Next month') {
            //     filterBooking()
            // }
        }

        const onCustomDateSelect = () => {
            selectedFilterDate.value = dayjs(filterCustomDate.value).format('DD-MMM-YYYY')
            filterBooking()
        }

        const calcDate = (dateCondt) => {
            let start = '';
            let end = '';
            let formatString = 'YYYY-MM-DD';

            if(dateCondt == 'This month') {
                start = dayjs().startOf('month').format(formatString)
                end = dayjs().endOf('month').format(formatString)
            }

            if(dateCondt == 'Last month') {
                start = dayjs().subtract(1, 'month').startOf('month').format(formatString)
                end = dayjs().subtract(1, 'month').endOf('month').format(formatString)
            }

            if(dateCondt == 'Next month') {
                start = dayjs().add(1, 'month').startOf('month').format(formatString)
                end = dayjs().add(1, 'month').endOf('month').format(formatString)
            }

            return {from: start, to: end}
        }

        //==============Filter Booking==============
        const selectedSupplier = ref()
        const filteredSupplier = ref()
        let supplier_field = ref('')

        const searchByTourCode = (event) => {
            clearTimeout(timeout);
            
            timeout = setTimeout(() => {
                const tourcode = event.target.value.toLowerCase().trim()
                if(tourcode != '') {
                    bookingService.value.searchByTourCode(event.target.value).then(data => {
                        if(!data.errorResponse) {
                            bookings.value = data
                        } else {
                            loadingError.isError = true;
                            loadingError.errorCode = data.status
                        }
                    })
                }
            }, 250);
        }

        // const onSupplierSelect = () => {
        //     filterBooking()
        // }

        const filterBooking = () => {
            if(selectedFilterDate.value) {
                let filterParams = {}
                if(selectedFilterDate.value.date) { 
                    filterParams = {
                        filterby: selectedFilterBy.value.by,
                        from: calcDate(selectedFilterDate.value.date).from,
                        to: calcDate(selectedFilterDate.value.date).to
                    }
                } else {
                    //IF FILTER DATE IS CUSTOM DATE USE THIS PARAMS
                    filterParams = {
                        filterby: selectedFilterBy.value.by,
                        from: dayjs(filterCustomDate.value).format('YYYY-MM-DD'),
                    }
                }

                if(selectedFilterBy.value.by == 'Booking') {
                    bookingService.value.filterBooking({queryParams: JSON.stringify(filterParams)}).then(data => {
                        if(!data.errorResponse) {
                            bookings.value = data
                        } else {
                            loadingError.isError = true;
                            loadingError.errorCode = data.status
                        }
                    })
                }

                if(selectedSupplier.value) {
                    filterParams.supplier_id = selectedSupplier.value.id

                    bookingService.value.filterBooking({queryParams: JSON.stringify(filterParams)}).then(data => {
                        if(!data.errorResponse) {
                            bookings.value = data
                        } else {
                            loadingError.isError = true;
                            loadingError.errorCode = data.status
                        }
                    })
                }
            }
        }

        const searchSupplier = (event) => {
            setTimeout(() => {
                if (event.query.trim().length) {
                    if(selectedFilterBy.value.by == 'Hotel') {
                        supplier_field.value = 'hotel_name'
                        hotelService.value.searchHotel(event.query.toLowerCase()).then(data => {
                            if(!data.errorResponse) {
                                if(data.length > 0) {
                                    filteredSupplier.value = data
                                } else {
                                    filteredSupplier.value = data = [{hotel_name: 'No result'}]
                                }
                            }
                        })
                    }

                    if(selectedFilterBy.value.by == 'Restaurant') {
                        supplier_field.value = 'restaurant_name'
                        restaurantService.value.searchRestaurant(event.query.toLowerCase()).then(data => {
                            if(!data.errorResponse) {
                                if(data.length > 0) {
                                    filteredSupplier.value = data
                                } else {
                                    filteredSupplier.value = data = [{restaurant_name: 'No result'}]
                                }
                            }
                        })
                    }

                    if(selectedFilterBy.value.by == 'Tour guide') {
                        supplier_field.value = 'guide_name'
                        tourguideService.value.searchTourGuide(event.query.toLowerCase()).then(data => {
                            if(!data.errorResponse) {
                                if(data.length > 0) {
                                    filteredSupplier.value = data
                                } else {
                                    filteredSupplier.value = [{guide_name: 'No result'}]
                                }
                            }
                        })
                    }

                    if(selectedFilterBy.value.by == 'Transport Company') {
                        supplier_field.value = 'company_name'
                        transService.value.searchTransportation(event.query.toLowerCase()).then(data => {
                            if(!data.errorResponse) {
                                if(data.length > 0) {
                                    filteredSupplier.value = data
                                } else {
                                    filteredSupplier.value = [{company_name: 'No result'}]
                                }
                            }
                        })
                    }
                }
            }, 250);
        }

        return {
            can,
            loadingError,
            bookings,
            selectedBooking,
            onRowSelect,

            activeTab,
            onTabChanged,
            confirmDeleteBooking,

            searchPlaceholder,
            groupedFilterBy,
            selectedFilterBy,
            filterDate,
            selectedFilterDate,
            onFilterDateChange,
            customDate,
            onCustomDateSelect,
            filterCustomDate,
            searchByTourCode,
            searchSupplier,
            supplier_field,
            selectedSupplier,
            filteredSupplier,
            filterBooking,

            BookingArrivalInfoRef,
            BookingItineraryInfoRef,
            BookingHotelRef,
            BookingRestaurantRef,
            BookingTourGuideRef,
            BookingTransportRef,
            BookingOptionalTourRef,
        }
    },
    components: {
        LoadingDataError,
        BookingArrivalInfo,
        BookingItineraryInfo,
        BookingHotel,
        BookingRestaurant,
        BookingTourGuide,
        BookingTransport,
        BookingOptionalTour,
    }
}
</script>

<style lang="scss" scoped>
.booking-datatable {
    height: 320px;
}
.tabmenu {
    height: auto;
    min-height: 25rem;
    padding: 0px 10px;
}
.p-input-icon-left i {
    z-index: 1;
}
</style>